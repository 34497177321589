"use client";

import { type ElementType } from "react";
import { twMerge } from "tailwind-merge";
import "../../../index.css";
import { customTwMerge } from "../../../utils/customTwMerge";
import { LabelProps } from "./Label.type";

export const Label = <T extends Extract<ElementType, "div" | "label"> = "div">({
  as,
  type = "default",
  size,
  icon,
  requiredText = "*",
  optionalText = "(선택 항목)",
  className,
  children,
  ...restProps
}: LabelProps<T>) => {
  const Element = (as || "div") as ElementType;

  return (
    <Element
      className={customTwMerge(
        "flex flex-wrap items-center",
        size === "small" && SMALL_SIZE_STYLE,
        size === "big" && BIG_SIZE_STYLE,
        className,
      )}
      {...restProps}
    >
      {children}
      {type === "required" && (
        <span
          className={twMerge(
            "text-context-error ml-[2px] min-w-[7px]",
            size === "big" && "ml-[3px]",
            size === "small" && "ml-[2px]",
          )}
        >
          {requiredText}
        </span>
      )}
      {type === "optional" && (
        <span
          className={twMerge(
            "text-text-subtlest c12-regular",
            size === "big" && !icon && "ml-[4px]",
            size === "big" && icon && "ml-[3px]",
            size === "small" && "ml-[2px]",
          )}
        >
          {optionalText}
        </span>
      )}
      {icon && icon}
    </Element>
  );
};

const SMALL_SIZE_STYLE = "c14-regular";
const BIG_SIZE_STYLE = "c16-regular";
